// Black
$dark: #22252A;
$black: #000000;
$black-2: #1A1A1A;
$black-3: #060606;
$black-4: #141414;
$black-5: #1A1818;
$black-6: #020202;
$trans-black: #00000029;
$trans-black-2: #00000057;
$trans-black-3: #0000005C;
$trans-black-4: #00000012;
$trans-black-5: #00000010;
$trans-black-6: #00000059;
// Black


// White
$light : #ffffff;
$white-50: #f8f9fa;
$white-2: #F2F2F2;
$white-3: #F6F6F6;
$white-4: #EBEBEB;
$white-5: #f5f5f5;
$white-6: #EFEEEE;
$white-7: #F5F4F4;
$white-8: #EFECEC;
$white-9: #FBFBFE;
$white-10: #EBE9E9;
$white-11: #E6E6E6;
$white-12: #f1f1f1;
// White


// Gray
$gary-p: #8F9198;
$gray-btn: #5B5B5B;
$gray-border: #BCBCBC;
$gray-p2: #9F9F9F;
$lighht-gray: #e4e0e0;
$light-gray-2: #D8D8D8;
$light-gray-3: #B1B1B1;
$light-gray-4: #8F9091;
$light-gray-5: #A2A2A2;
$trans-gray: #70707057;
$trans-gray-2: #70707073;
$gray: #484848;
$gray-2: #707070;
$gray-3: #3A3A3B;
$gray-4: #5D6D81;
$gray-5: #3E3E3E;
$gray-6: #585858;
$gray-7: #C9C9C9;
$gray-8: #929292;
$gray-9: #CCCCCC;
$gray-10: #8D8D8D;
$gray-11: #d6d6d6;
$gray-12: #C1C1C1;
$gray-13: #A1A1A1;
// Gray


// Orange
$orange: #FF9C46;
$orange-2: #E89B4D;
$light-orange: #F3A64E;
$light-orange-2: #FCF2E8;
// Orange


// Blue
$blue: #6280FF;
$blue-2: #0088FF;
$blue-3: #80B5FC;
$blue-4: #457AFB;
$blue-5: #0090C6;
$blue-6: #1478DC;
$Spiro-blue : #0DB1FF;
$light-blue: #0DB1FFBA;
$light-blue-2: #56C0F2;
$light-blue-3: #27B2C2;
$light-blue-4: #38A4D6;
$light-blue-5: #62B6FF;
$light-blue-6: #DEECFF;
$light-blue-7: #2c56f9;
// Blue


// Green
$green: #73C155;
$green-2: #16E78B;
$green-3: #45D354;
$green-5 :#569F87;
$green-9: #D3FFF1;
$dark-green: #08B902;
$dark-green-2: #00FF19;
$dark-green-3: #3ADB4A;
$dark-green-4: #83DA57;
$light-green: #9FDFCD;
$light-green-2: #F3F9F5;
$light-green-3: #D5E3D6;
$light-green-4: #EAFFDE;
// Green


// Purple
$purple :#9574FF;
$purple-2: #7B3DD9;
$purple-3: #A662FB;
$purple-4: #A764FB;
$purple-5: #AD67FE;
$purple-6: #AB66FC;
$dark-purple: #1B1464;
$dark-purple-2: #3B3B72;
$light-purple: #D79AD7;
$light-purple-2: #ACAFFF;
$light-purple-3: #B2BFFF;
$light-purple-4: #F3EBFF;
// Purple


// Pink
$pink :#FF3C9E;
$pink-9 : #FCEDF4;
$light-pink: #FFAAB9;
// Pink


// Red
$red: #FF0000;
$red-2: #F25656;
$red-3: #FF6262;
$light-red: #FFE5E5;
// Red

// Yellow
$yellow-1: #FFD700;
$yellow-2: #D2BD49;
$dark-yellow: #6B5B00;
// Yellow