@import './mixins/layout';
@import './theme/theme';
@import './theme/color';

body {
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}

* {
    font-family: 'Montserrat', sans-serif !important;
}

.list-inline {
    list-style: none;
    padding: 0;
    margin: 0;
}

.wrapper {
    min-height: 100vh;
    padding: 15px;
}

.wrapper::before {
    position: absolute;
    bottom: 0;
    left: 0;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-b-50 {
    margin-bottom: 50px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-t-50 {
    margin-top: 50px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-l-50 {
    margin-left: 50px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-r-50 {
    margin-right: 50px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-b-40 {
    padding-bottom: 40px;
}

.p-b-50 {
    padding-bottom: 50px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-40 {
    padding-top: 40px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-l-50 {
    padding-left: 50px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-r-50 {
    padding-right: 50px;
}

.margin-bottom {
    margin-bottom: 22px;
}

.content {
    border-radius: 34px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    overflow: auto;
    height: 90vh;
    // height: 880px;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }
}

.content-section {
    height: calc(100% - 80px);
}

.app-wrapper {
    padding: 25px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.timeline-div {
    border-radius: 36px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .Kalend__CalendarBody {
        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
            margin-block: 20px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

.home-div1,
.home-div3 {
    // margin-right: 25px;
    max-width: 100%;
    height: 100%;
}

.home-div1:not(.first-div, .recentProjects-parent) {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;

}

.first-div {
    padding-bottom: calc(94.12px - 22.22px);
}

.home-div2 {
    // margin-right: 25.29px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
    max-width: 100%;
    height: 100%;
}


.h1 {
    margin-top: calc(51.49px - 25px);
    font-size: 52px;
}



.p {
    font-size: 20px;
    margin-bottom: 0;
    margin-bottom: 52px;
}

.p2 {
    font-size: 13px;
    margin-bottom: 0;
    max-width: 50%;
    width: 100%;

}

.add-emp-btn {
    max-width: 181px;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
}

.add-emp-btn {
    span.anticon.anticon-plus {
        font-size: 16px;
        margin-right: 10px;
    }
}

.home-div3 {
    margin-left: auto;
    padding: 12.94px 14.82px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
    max-width: 500px;
}

.chart-p {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1;
    padding-bottom: 13.94px;
    margin-bottom: 0;
    text-align: left;
}

.products-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.products-charts-child1 {
    width: 91px;
    height: 94px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    position: relative;
}

.products-charts-child1:nth-child(1):after {
    content: '';
    position: absolute;
    top: 80%;
    left: 75%;
    width: 10px;
    height: 45px;
    z-index: 1;
    transform: rotate(-30deg);
}

.products-charts-child1:nth-child(2) {
    & :after {
        content: '';
        position: absolute;
        top: 80%;
        left: 75%;
        width: 10px;
        height: 45px;
        z-index: 1;
        transform: rotate(-30deg);
    }
}

.products-charts-child1:nth-child(3) {
    margin-right: 0;
}

.products-charts-child1:nth-child(4) {
    margin-left: 33px;

    & :after {
        content: '';
        position: absolute;
        top: -21%;
        right: 10%;
        width: 10px;
        height: 45px;
        z-index: 1;
        transform: rotate(30deg);
    }
}

.products-charts-child1:nth-child(5) {
    & :after {
        content: '';
        position: absolute;
        top: -21%;
        right: 10%;
        width: 10px;
        height: 45px;
        z-index: 1;
        transform: rotate(30deg);
    }
}

.products-charts-child2 {
    width: 81px;
    height: 84px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.products-charts-child3 {
    width: 52px;
    height: 54px;
    border-radius: 50%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    ;
}

.products-charts-child3 h1 {
    font-size: 26px;
}

.weekley-leads-chart-parent {
    padding: 13.39px 16.92px 27.86px 15.66px;
}

.weekly-div1 {
    margin-bottom: 15.18px;
}

.weekly-div1 h1 {
    font-size: 18px;
}

.monthly-div {
    h1 {
        font-size: 18px;
    }
}

.pending-task-chart-parent {
    h1 {
        font-size: 17px;
    }

    p:not(p:first-child) {
        font-size: 9px;
        letter-spacing: 0.9;
        text-align: center;
        margin-bottom: 0;
    }
}


.monthly-chart-parent {
    padding: 17px 44.65px 27px 46px;
    overflow-x: auto;
}

.monthly-chart-parent div:first-child {
    margin-right: 58px;
}

.monthly-chart-parent h1:not(.monthly-div h1) {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 600;
    max-width: 90%;
}

.monthly-chart-parent h1:last-child {
    padding: 0 0 0 5px;
}


.pending-task-chart-parent {
    padding: 18.97px 25.6px 10.14px 22.8px;
}


// Dashboard Responsive Table Start
.responsive-table {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    td:nth-child(1),
    td:nth-child(3) {
        width: 30%;
    }

    td {
        padding: 10px;

    }

    thead {
        tr {

            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            margin-bottom: 0;

        }
    }

    tbody {

        tr {
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 0;
            font-size: 12px;
        }

        td img {
            margin-right: 27.45px;
        }
    }

    button.status-warning-btn {
        font-size: 10px;
        border: none;
        outline: none;
        border-radius: 6px;
        padding: 4.67px 7.78px;
    }

    button.status-primary-btn {
        font-size: 10px;
        border: none;
        outline: none;
        border-radius: 6px;
        padding: 4.67px 28.332px;
    }


}

// Dashboard Responsive Table End


// Recent Activity Section Start
.currentActivity-child1 {
    padding: 19px 10px 22px;
}

.recent-activity-table {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;

    td:not(td:nth-child(1)) {
        text-align: right;
    }

    td {
        padding: 5px;
    }

    tr {
        font-size: 10px;
        margin-bottom: 5px;

        p {
            margin-bottom: 0;
        }
    }

    thead {
        text-transform: uppercase;
        letter-spacing: 0.03em;
        margin-bottom: 0;
    }

    tbody {
        tr {
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
            opacity: 1;

            .activity-img {
                width: 31px;
                height: 31px;
                border-radius: 50%;
                margin-right: 5px;
            }

            p:nth-child(2) {
                font-size: 10px;
            }
        }
    }

}

// Recent Activity Section Start

button.seeAll-btn {
    font-size: 10px;
    border-radius: 6px;
    border: none;
    padding: 7px 19.4px;
    cursor: pointer;
}

// Add Project Button Start
.add-proj-btn {
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 31px;
    cursor: pointer;

    span.anticon.anticon-plus {
        width: 38px;
        height: 38px;
        border-radius: 22px;
        opacity: 1;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;
        font-size: 14px;
    }
}

// Add Project Button End



// Customer Inner Body Start
.InnerBodyContent {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 32px;
    // opacity: 0.74;

    .customers:nth-child(1) {
        margin: 0 24px 0 38px;
    }

    .customers:nth-child(2) {
        margin: 0 24px 0 0;
    }

    .customers:nth-child(3) {
        margin: 0 24px 0 0;
    }

    .customers-parent-div {
        margin-block: 58px;

        .customers {

            .ant-progress-bg,
            .ant-progress-success-bg {
                border-radius: 0;
            }

            .ant-progress-inner {
                border-radius: 0;
            }

            border-radius: 18px;
            opacity: 1;
            width: 237px;
            height: 127px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;

            & h1 {
                margin-bottom: 0;
                font-size: 38px;
                letter-spacing: 0px;
                opacity: 1;
            }

            & p {
                font-size: 12px;
                letter-spacing: 0px;
                opacity: 1;
                margin-bottom: 0;
                font-weight: 600;
            }
        }
    }


    .projects-image {
        width: 100%;
        display: flex;
        padding-inline: 30px;

        .projectImageInner:nth-child(1) {
            background-image: url('../../assets/images/project1.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .projectInnerContent {
                text-align: center;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }

        .projectImageInner:nth-child(2) {
            background-image: url('../../assets/images/project2.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            margin-left: -160px;
            display: flex;
            align-items: center;
            justify-content: end;

            .projectInnerContent {
                text-align: center;
                margin-right: 60px;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }

        .projectImageInner:nth-child(3) {
            background-image: url('../../assets/images/project3.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            margin-left: -160px;
            display: flex;
            align-items: center;
            justify-content: end;

            .projectInnerContent {
                text-align: center;
                margin-right: 60px;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }

        .projectImageInner:nth-child(4) {
            background-image: url('../../assets/images/project4.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            margin-left: -160px;
            display: flex;
            align-items: center;
            justify-content: end;

            .projectInnerContent {
                text-align: center;
                margin-right: 50px;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }
    }

    .customer-right-sec {
        margin-right: 55px;
        margin-top: calc(118px - 58px);
    }

}

// Customer Inner Body End




// Searchbar and Export button Start
.customer-right-sec {

    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;

}

.search-bar {
    max-width: 367px;
    width: 100%;
    margin-bottom: 20px;
}

// Searchbar and Export button End



// project Info Styling Start
.projectInfoBody {
    width: 100%;
    height: 100%;
    border-radius: 32px;
    opacity: 0.74;
    overflow: hidden;

    .projectInfoLeftSection {
        padding: 30px;
        width: 100%;
        height: 100%;

        & h1 {
            margin-bottom: 31px;
            font-size: 26px;
            text-transform: uppercase;
        }


        .projectName {
            width: 100%;
            margin-bottom: 31px;

            & h1 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 17px;
            }

            & p {
                font-size: 17px;
                margin: 0;
            }

        }



        .progressChart {
            margin-bottom: 21px;
            text-align: center;
        }

        .projectMembers {
            & p {
                font-size: 16px;
                margin-bottom: 0;
            }

            .member-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 13px;

                & p {
                    font-size: 17px;
                    margin-bottom: 0;
                }
            }

            .member-images {
                margin-bottom: 13px;
            }
        }
    }

    .projectInfoRightSection {
        width: 100%;
        height: 100%;
        border-radius: 32px;
        opacity: 1;
        padding: 45px 24px 35px 10px;


    }

}

// project Info Styling End




// Antd Table Styling Start

.ant-table-pagination.ant-pagination {
    justify-content: center;
}

.ant-table {
    table {
        border-collapse: separate;
        border-spacing: 0 10px;
        overflow: auto;
    }
}

.ant-table-thead>tr {
    th {
        position: relative;
        font-weight: 600;
        background: transparent;
        border-bottom: 0;
        padding: 10px;
        text-transform: uppercase;
    }

    th:last-child {
        text-align: center;
    }
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    visibility: hidden;
}

.ant-table-tbody>tr {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    td {
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
        opacity: 1;
    }
}


.ActiveBtn {
    display: flex;
    align-items: center;
    border-radius: 5px;
    opacity: 1;

    & svg {
        margin-right: 5px;
    }
}

.editbtn {
    border-radius: 6px;
    opacity: 1;
    border: 0;
}

.deletebtn {
    border-radius: 6px;
    opacity: 1;
    border: 0;
}

.vline {
    height: 30px;
    margin-inline: 3px;
}

.ant-table {
    width: 100%;
    overflow-x: auto;
}

// Antd Table Styling End



// Ant Design Tabs Start

.card-container p {
    margin: 0;
}

.card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}

.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    padding: 16px;
    border-radius: 0 14px 14px 14px;
}

.card-container>.ant-tabs-card>.ant-tabs-nav::before {
    display: none;
}

.card-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

#components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
}

[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
    border-radius: 14px 14px 0 0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: 0 0 .25px currentcolor;
    font-size: 18px;
}

.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
    outline: none;
    transition: all .3s;
    font-size: 18px;
    border-bottom: 3px solid transparent;
}

// Ant Design Tabs End


// Ant design Modal Styling
.ant-modal-content {
    border-radius: 30px
}

.ant-modal-close {
    position: absolute;
    left: 100%;
}

.ant-modal-close-x {
    line-height: 0;
    font-size: 25px;
}

.ant-modal-body {
    padding: 19px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    border-radius: 25px;
    overflow: hidden;
}

.ModalBody {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .ModalLeftSec {
        width: 100%;
        height: 850px;
        border-radius: 24px;
        opacity: 1;
        padding: 22px 22px 29px 27px;

        & h1 {
            font-size: 24px;
        }

        .projectName {
            width: 100%;
            margin-block: 40px;

            & h1 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 10px;
            }

            & p {
                font-size: 17px;
                margin: 0;
            }

        }

        .projectDetails {
            width: 100%;
            margin-bottom: 31px;
            border-collapse: separate;
            border-spacing: 0 10px;

            & tr {
                & td:first-child {
                    font-size: 17px;
                }

                & td:last-child {
                    font-size: 17px;
                }
            }
        }

        .projectMembers {
            margin-top: 200px;

            & p {
                font-size: 16px;
                margin-bottom: 0;
            }

            .member-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 13px;

                & p {
                    font-size: 17px;
                    margin-bottom: 0;
                }
            }

            .member-images {
                margin-bottom: 13px;
            }
        }
    }

    .ModalMiddleSec {
        width: 100%;
        height: 850px;
        padding: 22px 12px 50px 43px;

        & h1 {
            font-size: 25px;
            text-transform: uppercase;
            margin-bottom: 63px;
        }

    }

    .ModalRightSec {
        width: 100%;
        height: 850px;
        border-radius: 24px;
        opacity: 1;
        padding: 50px 5px 50px 24px;


    }
}

// Ant design Modal Styling


// Primary Button
.primaryBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}

// Primary Button


// secondary Button
.secondaryBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}

// secondary Button

// success Button
.successBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}

// success Button

// danger Button
.dangerBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}

// danger Button


// Todo App Start
.todo {
    width: 100%;
    height: 750px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: end;

    ul {
        padding-right: 20px;
        padding-left: 0;
        list-style-type: none;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 34px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px;
            margin-block: 30px;

        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(114, 114, 114, 0.5);
            border-radius: 10px;
            margin-block: 30px;
        }

        & p {
            margin: 0;
            font-size: 17px;
            font-weight: 600;
        }

        li.todo-list {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px 20px;
            border-radius: 0px 20px 20px 20px;
            font-size: 17px;
            font-weight: 600;
        }
    }

    .todoChild {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 20px;

        .ant-input-affix-wrapper {
            border-radius: 36px;
            opacity: 1;
            margin-right: 10px;

            input[type=text] {
                padding: 10px;
            }

        }

        .primaryBtn {
            padding: 15px;
            opacity: 1;
            border-radius: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}

// Todo App End


.ModalLeftSec {
    .ticketInfo {
        font-size: 24px;
    }
}


.ModalMiddleSec {
    .dangerBtn {
        margin-bottom: 63px;
        font-weight: bold;
        font-size: 20px;
        padding: 7px 46.5px;
    }
}

// Midle Section Content start
.ModalMiddleSecContent {
    height: 410px;
    padding-right: 25px;
    margin-bottom: 110px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }


    & p {
        margin: 0;
        font-size: 17px;
        font-weight: bold;
    }

    & p:nth-child(2) {
        margin-bottom: 20px;
    }

    & p:nth-child(4) {
        margin-bottom: 20px;
    }
}

// Middle Section Content End

// Midle Section Content 2 start
.ModalMiddleSecContent2 {
    height: 410px;
    padding-right: 25px;
    margin-bottom: 110px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }


    & p {
        margin: 0;
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 35px;
    }
}

// Middle Section Content 2 End


// Modal Middle Sectiion Download File Section  Start
.FilesDownloadSec {
    padding-top: 22px;
    padding-right: 20px;
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }
}

.imgFormatSec {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-block: 30px;

    .format {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        & p {
            margin: 0;
        }

        .formatImg {
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            & p {
                font-size: 17px;
            }
        }

        .formatContent p:first-child {
            font-size: 17px;
        }

        .formatContent p:last-child {
            font-size: 13px;
        }
    }
}

// Modal Middle Sectiion Download File Section  End


// Profile Info start
.profileInfo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 31px;

    .profileInfo-content {
        & h1 {
            font-size: 24px;
            text-transform: capitalize;
            margin: 0;
        }

        & p {
            font-size: 18px;
            margin: 0;
        }
    }
}

// Profile Info end

// Details Table
.projectDetails {
    width: 100%;
    margin-bottom: 31px;
    border-collapse: separate;
    border-spacing: 0 10px;

    & tr {
        & td:first-child {
            font-size: 17px;
        }

        & td:last-child {
            font-size: 17px;
        }
    }
}

// Details Table

// Customer Profile Page
.customerProfile {
    .ant-btn-sm {
        width: 44px;
        height: 27px;
        border-radius: 4px;
        float: right;
    }

    .projectDetails {
        margin-bottom: 99px;

        & tr {
            & td:first-child {
                font-size: 13px;
                width: 50%;
            }

            & td:last-child {
                font-size: 13px;
                width: 50%;
            }
        }
    }

    .customerRating {
        & p:first-child {
            font-size: 16px;
            margin-bottom: 7px;

        }

        & p:last-child {
            font-size: 13px;
            margin-bottom: 7px;
            padding: 13px 31px 12px 30px;
            border-radius: 15px;
            opacity: 1;

        }

        .ant-rate {
            margin-bottom: 19px;
        }
    }

    .topSec {
        display: flex;
        justify-content: space-between;
    }
}

// Customer Profile Page


// Outline Danger Button
.OutlineDangerBtn {
    max-width: 250px;
    width: 100%;
    height: 46px;
    font-size: 17px;
    border-radius: 11px;
    opacity: 1;
    text-transform: uppercase;
}

// Outline Danger Button

// Ant design Invoice Modal Styling
.InvoiceModalBody {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .ModalLeftSec {
        width: 100%;
        height: 850px;
        border-radius: 24px;
        opacity: 1;
        padding: 29px;

        & h1 {
            font-size: 24px;
        }

        .projectName {
            width: 100%;
            margin-block: 31px;

            & h1 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 10px;
            }

            & p {
                font-size: 17px;
                margin: 0;
            }

        }

        .projectDetails {
            width: 100%;
            margin-bottom: 31px;
            border-collapse: separate;
            border-spacing: 0 8px;

            & tr {
                & td:first-child {
                    font-size: 17px;
                }

                & td:last-child {
                    font-size: 17px;
                }
            }
        }

        .OutlineDangerBtn {
            margin-bottom: 50px;
        }

        .primaryBtn {
            padding: 8px 27px;
            margin-bottom: 9px;
        }

        .successBtn {
            margin-bottom: 50px;
        }

        .InvoiceOptions {
            max-width: 100%;
            text-align: center;

            .InvoiceOptionsChild {
                width: 35px;
                height: 34px;
                border-radius: 8px;
                opacity: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            & span {
                font-size: 8px;
            }
        }
    }

    .ModalMiddleSec {
        width: 100%;
        height: 850px;
        padding: 39px 0 50px 19px;

        & h1 {
            font-size: 31px;
            text-transform: uppercase;
            margin-bottom: 44px;
            font-weight: bold;
        }

        .projectDetails {
            width: 100%;
            margin-bottom: 73px;
            border-collapse: separate;
            border-spacing: 0 10px;

            thead {

                tr {
                    td {
                        padding-block: 8px;
                        font-size: 12px;
                    }

                    padding-top: 1000px;

                    td:nth-child(1) {
                        padding-left: 10px;
                    }

                    td:nth-child(2) {
                        text-align: center;
                        width: 60%;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    td:nth-child(2) {
                        width: 60%;
                    }
                }

            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin-left: auto;
            max-width: 195px;

            li {
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: 6px;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }

    }
}

// Ant design Invoice Modal Styling

// Invoice Page Styling Start
.invoice-status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 22px 40px;

    .invoice-progress-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .invoice-text {
            margin-left: 10px;

            & p {
                margin-bottom: 0;
            }

            & p:first-child {
                font-size: 25px;
            }

            & p:last-child {
                font-size: 13px;
            }
        }
    }

}

.invoice-anmout-details {
    position: relative;
    width: 233px;
    height: calc(114px - 20px);
    opacity: 1;
    border-radius: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;

    &::after {
        content: '';
        width: 125px;
        height: 15px;
        position: absolute;
        left: 0;
        top: -15px;
        border-right: 25px solid transparent;
        border-radius: 10px 0 0 0;
    }

    & .ant-progress.ant-progress-line {
        position: absolute;
        bottom: 0;
        font-size: 0;

        & .ant-progress-bg {
            height: 5px !important;
        }
    }

    & p:nth-child(1) {
        font-size: 11px;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    & p:nth-child(2) {
        font-size: 29px;
        margin-bottom: 0;
        font-weight: bold;
    }
}

// Invoice Page Styling End

// Merchant Page Start
.MerchantHeadContent {
    margin-bottom: 40px;
    display: flex;
    justify-content: end;
    align-items: center;

    svg {
        width: 38px;
        height: 30px;
        cursor: pointer;
    }
}

// Merchant Page End

// Leads Page Start
.LeadsHeadContent,
.TargetHeadContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    svg:nth-child(2) {
        width: 38px;
        height: 30px;
        cursor: pointer;
    }
}

.leads-anmout-details {
    position: relative;
    width: 167px;
    height: calc(114px - 20px);
    opacity: 1;
    border-radius: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &::after {
        content: '';
        width: 75px;
        height: 15px;
        position: absolute;
        left: 0;
        top: -15px;
        border-right: 25px solid transparent;
        border-radius: 10px 0 0 0;
    }

    & .ant-progress.ant-progress-line {
        position: absolute;
        bottom: 0;
        font-size: 0;

        & .ant-progress-bg {
            height: 5px !important;
        }
    }

    & p:nth-child(1) {
        font-size: 29px;
        margin-bottom: 0;
        font-weight: bold;
    }

    & p:nth-child(2) {
        font-size: 11px;
        margin-bottom: 0;
        text-transform: uppercase;
    }
}

// Leads Page End

// Leads Info Modal Start
.leadsInfoModal {
    .ant-modal-body {
        background-image: url('../images/leadsInfoBg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        padding: 0;
        border-radius: 30px;

        .ant-col {
            margin-top: 44px;
            margin-bottom: 47px;

            & h2 {
                font-size: 34px;
                width: 100%;
                max-width: 234px;
                text-align: center;
                margin-bottom: 0;
            }
        }

        .leadsInfoBody {
            padding: 50px 0 50px 50px;
            width: 100%;
            height: 100%;
            position: relative;

            & table {
                width: 100%;
                margin-bottom: 50px;

                & tr {
                    & td {
                        font-size: 17px;
                        padding: 10px;
                    }
                }

                & tr:first-child {
                    & td {
                        border-top: 0;
                    }
                }
            }

            .social-icons {
                display: flex;

                .icon-1 {
                    margin-right: 30px;

                    a {
                        width: 49px;
                        height: 49px;
                        border-radius: 8px;
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    & p {
                        margin-bottom: 0;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

// Leads Info Modal End

// Target Page Start
.target-right-sec {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    margin-right: 50px;
    height: 100%;

    span.search-bar {
        margin-bottom: 23px;
    }
}

.target-status-details {
    position: relative;
    width: 242px;
    height: calc(175px - 35px);
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 35px solid transparent;
        border-right: 35px solid transparent;
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
    }

    & .ant-progress.ant-progress-line {
        position: absolute;
        bottom: 0;
        font-size: 0;

        & .ant-progress-bg {
            height: 5px !important;
        }
    }

    & p:nth-child(1) {
        font-size: 16px;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    & p:nth-child(2) {
        font-size: 44px;
        margin-bottom: 0;
        font-weight: bold;
    }
}

.steps-parent {
    position: relative;
    margin: 20px 8px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    .target-steps {
        display: flex;
        width: 100%;
        justify-content: space-around;
        position: relative;


        &::before {
            content: "";
            width: 100%;
            position: absolute;
            top: 50%;
            z-index: 0;
        }

        div {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            z-index: 1;
        }
    }
}

.sales-employee-parent {
    margin-block: 120px;

    .ant-row {
        .ant-col {
            .sales-employee {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                position: relative;
                width: 100%;
                max-width: 280px;
                height: 350px;
                border-radius: 17px;
                opacity: 1;
                text-align: center;
                margin: auto;
                margin-bottom: 90px;

                & p.v-text {
                    display: none;
                }

                .sales-employee-child1 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    position: relative;
                    top: -30px;
                    width: 217px;
                    height: calc(282px - 40px);
                    opacity: 1;
                    border-radius: 30px;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -35px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-left: 25px solid transparent;
                        border-right: 25px solid transparent;
                    }

                    .popover {
                        position: absolute;
                        right: -90px;
                        top: 15px;
                    }

                    .sales-employee-child1-subChild1 {
                        width: 100%;
                        max-width: 110px;
                        height: 110px;
                        border-radius: 50%;
                        position: relative;
                        top: -20px;

                        & img {
                            border-radius: 50%;
                        }
                    }

                    .sales-employee-child1-subChild2 {
                        & p {
                            margin: 0;
                        }

                        & p:nth-child(1) {
                            font-size: 21px;
                        }

                        & p:nth-child(2) {
                            font-size: 15px;
                            margin-bottom: 10px;

                        }

                        & p:nth-child(3) {
                            font-size: 14px;

                        }

                        & p:nth-child(4) {
                            font-size: 30px;
                            margin-bottom: 10px;

                        }
                    }
                }

                .sales-employee-child2 {

                    p {
                        text-align: center;
                        margin: 0;

                    }

                    & p:nth-child(1) {
                        font-size: 14px;
                    }

                    & p:nth-child(2) {
                        font-size: 49px;
                    }
                }
            }
        }

        .ant-col:nth-child(1) {
            .sales-employee {

                & p.v-text {
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 30px;
                    writing-mode: vertical-rl;
                    font-weight: bold;
                    font-size: 17px;
                    transform: rotate(180deg);
                    letter-spacing: 0.5em;
                    margin: 10px 0 10px 10px;
                }
            }
        }
    }

}

// Target Page End

// PopOver Start
.popover {
    display: flex;

    & button.ant-btn.ant-btn-default.ant-btn-icon-only {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;

        & svg {
            width: 20px;
            height: 20px;
        }
    }

    .popover-content {
        visibility: hidden;
        opacity: 0;
        width: 99px;
        height: 51px;
        opacity: 1;

        & a {
            margin: 0;
            font-size: 14px;
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }
    }
}

.popover.active {
    & button.ant-btn.ant-btn-default.ant-btn-icon-only {
        & svg {
            transform: rotate(180deg);
        }
    }

    .popover-content {
        visibility: visible;
        opacity: 1;
    }
}

// PopOver End

// Upload Image Start
.avatar-upload {
    position: relative;
    max-width: 170px;
    margin: 40px 0 16px 0;

    .avatar-edit {
        position: absolute;
        right: 10px;
        z-index: 1;
        bottom: 0;

        input {
            display: none;

            +label {
                display: inline-block;
                width: 39px;
                height: 39px;
                margin-bottom: 0;
                border-radius: 100%;
                border: 1px solid transparent;
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;

                & svg {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }

    .avatar-preview {
        width: 165px;
        height: 165px;
        position: relative;
        border-radius: 100%;

        >div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

// Upload Image End

// Edit Employee Details Modal Start
.ant-modal-body {
    .editEmployeeDetails {
        .ant-row {
            .ant-col {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & p {
                    font-size: 19px;
                }

                .select-parent {
                    margin-bottom: 26px;

                    & p {
                        margin-bottom: 0;
                        font-size: 16px;
                    }

                    .ant-select {
                        height: 50px;

                        .ant-select-selector {
                            height: 50px;
                            align-items: center;
                            border-radius: 11px;
                            opacity: 1;
                        }
                    }
                }

                .assign-target {
                    margin-bottom: 26px;
                    display: flex;
                    justify-content: space-between;
                    align-items: end;

                    .select-parent {
                        margin-bottom: 0;
                    }

                    .ant-input {
                        width: 192px;
                        height: 50px;
                        border-radius: 11px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Edit Employee Details Modal End

// Sales Analytics Start
.sales-analytics-parent {
    padding: 0 0 0 30px;

    .ant-row {
        .ant-col {
            .sales-analytics {
                display: flex;

                .sales-analytics-child-1 {
                    width: 81px;
                    height: 70px;
                    border-radius: 14px;
                    opacity: 1;
                    position: relative;

                    & svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 48px;
                        height: 48px;
                    }
                }

                .sales-analytics-child-2 {
                    margin-left: 12px;

                    & p {
                        margin-bottom: 0;
                    }

                    & p:nth-child(1) {
                        font-size: 16px;
                    }

                    & p:nth-child(2) {
                        font-size: 35px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

}

// Sales Analytics Start

// Tickets Section Start
.tickets-sec {
    display: flex;
    margin: 40px 15px 0 25px;

    .ticket {
        position: relative;
        width: 100%;
        max-width: 277px;
        height: 145px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 5px;

        &:after {
            content: "";
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: absolute;
            right: -25px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }

        p {
            margin-bottom: 0;
        }

        p:nth-child(1) {
            font-size: 18px;
            text-transform: uppercase;
        }

        p:nth-child(2) {
            font-size: 45px;
            font-weight: bold;
        }

        .ant-progress {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            font-size: 0;

            .ant-progress-bg {
                height: 5px !important;
            }
        }
    }
}

.tickets-right-sec {
    height: 100%;
    margin-top: 0 !important;
}

// Tickets Section End


// Media Query Start
@media(max-width:1600px) {

    .tickets-sec,
    .invoice-anmout-details,
    .leads-anmout-details,
    .target-status-details,
    .sales-analytics {
        margin-bottom: 30px;
    }

    .m-t-0 {
        margin-top: 0 !important;
    }
}

@media(max-width:1280px) {
    .ant-table-tbody>tr td {
        font-size: 12px;
        padding: 5px;
    }

    .ant-table-tbody>tr td .ant-btn {
        font-size: 12px;
        padding: 4px 10px;
    }

    .ant-table-thead>tr th {
        font-size: 12px;
        padding: 5px;
        vertical-align: baseline;
    }

    .projectInfoBody .projectInfoLeftSection {
        padding: 30px 15px;

        h1 {
            margin-bottom: 25px;
            font-size: 16px;
        }

        .profileInfo {
            .profileInfo-content h1 {
                font-size: 16px;
                margin-bottom: 0;
            }

            .profileInfo-content p {
                font-size: 15px;
            }
        }

        .projectName {
            & p {
                font-size: 15px;
            }

            & h1 {
                font-size: 16px;
                margin-bottom: 1rem;
            }
        }

        .projectDetails {
            tr {
                td {

                    &:first-child,
                    &:last-child {
                        font-size: 15px;
                    }
                }
            }
        }

        .projectMembers {
            .member-title {
                & p {
                    font-size: 15px;
                }
            }
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            font-size: 15px;
        }
    }

    .ant-tabs-tab {

        .ant-tabs-tab-btn,
        .ant-tabs-tab-remove {
            font-size: 15px;
        }
    }

    .ant-input-affix-wrapper,
    .ant-picker,
    .ant-btn,
    .ant-picker-input>input {
        font-size: 12px;
    }

    .ant-modal:not(.ant-modal.target_modal) {
        width: 100% !important;
        height: 97%;
        border-radius: 25px;
        overflow-y: auto !important;

        .ant-modal-body {
            width: 100%;
        }

        .ant-modal-close {
            position: fixed;
            top: 20px;
            left: 96.5%;
        }

        .ant-modal-close-x {
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: #5D6D81;
        }
    }

    .ModalBody {
        .ModalLeftSec {

            h1,
            .ticketInfo {
                font-size: 18px;
            }

            .projectName {
                p {
                    font-size: 15px;
                }

                h1 {
                    font-size: 16px;
                }
            }

            .projectDetails {
                margin-bottom: 20px;

                tr {
                    td {

                        &:first-child,
                        &:last-child {
                            font-size: 16px;
                        }
                    }
                }
            }

            .primaryBtn,
            .dangerBtn {
                padding: 7px 20px;
                font-size: 15px;
            }

            .projectMembers {
                .member-title {
                    p {
                        font-size: 15px;
                    }
                }
            }
        }

        .ModalMiddleSec {
            h1 {
                font-size: 20px;
                margin-bottom: 2rem;
            }

            .ModalMiddleSecContent {
                margin-bottom: 80px;

                & p {
                    font-size: 15px;
                }
            }

            .secondaryBtn {
                padding: 7px 30px;
                font-size: 15px;
            }

            .imgFormatSec {
                .format {
                    .formatImg {
                        width: 50px;
                        height: 50px;

                        p {
                            font-size: 15px;
                        }
                    }

                    .formatContent {
                        p {
                            &:first-child {
                                font-size: 15px;
                            }

                            &:last-child {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
        padding: 10px;
    }

    .customerProfile {
        .projectDetails {
            margin-bottom: 30px;

            tr {
                td {

                    &:first-child,
                    &:last-child {
                        font-size: 12px !important;
                        vertical-align: baseline;
                    }
                }
            }
        }
    }

    .ModalMiddleSec {
        .dangerBtn {
            padding: 7px 30px;
            font-size: 15px;
        }

        .ModalMiddleSecContent2 {
            margin-bottom: 40px;

            p {
                font-size: 15px;
            }
        }
    }

    .ant-modal.target_modal {
        .ant-modal-close {
            top: -10px;
            left: 98%;
        }

        .ant-modal-close-x {
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: #ffffff;
        }
    }

    .open {
        .has-dropdown {
            .drodown-icon {
                font-size: 16px !important;
                margin-left: 0 !important;
            }
        }

        .sidebar-items {
            span {
                font-size: 16px !important;
            }
        }
    }
}

// Media Query End

// Login Start
.rounded-0 {
    border-radius: 0;
}

.shadow-none {
    box-shadow: none;
}

.loginBg {
    background-image: url('../images/login-bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

#auth-logo {
    padding: 10% 11%;
    background: linear-gradient(212deg, rgba(44, 86, 249, 1) 0%, rgba(22, 0, 66, 1) 100%);
}

#auth-form {
    max-width: 80%;
    margin: 10% auto;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-end {
    justify-content: flex-end;
}

.text-center {
    text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

#auth-form {
    max-width: 80%;
    margin: 10% auto;
    font-family: 'Montserrat', sans-serif;
}

#auth-form input {
    border: none;
    border-bottom: .1px solid rgba(0, 0, 0, 0.082);
    margin-bottom: 25px;
    padding-block: 3px;
    background-color: transparent;
}

#auth-form input[type="text"] {
    height: 34px;
}

#auth-form input[type=checkbox],
#auth-form input[type=radio] {
    border: 1px solid #05050508;
    accent-color: white;
}

#auth-form input:focus {
    border-bottom: 1px solid rgba(0, 0, 0, 0.373);
    outline: none;
    transition: all .5s ease-in-out;
}

#auth-form button {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
    display: block;
    width: 100%;
    background: #03a9f3;
    border: 1px solid #03a9f3;
    color: white;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mt-5 {
    margin-top: 5rem;
}

.text-dark {
    color: black;
}

#remember {
    margin-right: 3px;
}

.cp {
    cursor: pointer;
}

.fs-4 {
    font-size: 1.3rem !important;
}

.fs-5 {
    font-size: 1rem !important;
}

.fs-6 {
    font-size: 0.9rem !important;
}

.fs-7 {
    font-size: 0.8rem !important;
}

.fs-8 {
    font-size: 0.7rem !important;
}

.fw-bold {
    font-weight: bold;
}

.my-table-wrapper {
    width: 100%;
    max-height: 100%;
    overflow: auto;
}

.my-table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    color: rgba(0, 0, 0, .85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    font-size: 14px;
    padding: 20px;
}

.my-table thead tr {
    box-shadow: none;
    border: none;
}

.my-table tr {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgba(0, 0, 0, 0.258);
}

.my-table tr td:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.258);
    border-top: 1px solid rgba(0, 0, 0, 0.258);
    border-bottom: 1px solid rgba(0, 0, 0, 0.258);
}

.my-table tr td:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.258);
}

.my-table tr td {
    border-top: 1px solid rgba(0, 0, 0, 0.258);
    border-bottom: 1px solid rgba(0, 0, 0, 0.258);
}

.my-table td,
.my-table th {
    padding: 10px;
}

.bg-incomplete {
    background-color: #d21010 !important;
}

.bg-completed {
    background-color: #679c0d !important;
}

.bg-in-progress {
    background-color: #FFD700 !important;
}

.bg-under-review {
    background-color: #000080 !important;
}

.css-13cymwt-control {
    padding: 3px !important;
}

.badge {
    border-radius: 8px;
    background: #4160cc;
    padding: 5px;
    font-size: 13px;
    border: none;
    color: white;
}

.my-form input,
.my-form select,
.my-form textarea {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.454);
    margin-bottom: 15px;
}

.bg-blue {
    background-color: $light-blue-7;
}

.headerStyle {
    border-radius: 8px 8px 0 0 !important;
    background-color: $light-blue-7;
    padding: 20px 25px;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.me-0 {
    margin-right: 0 !important;
}

.me-1 {
    margin-right: 0.25rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.me-4 {
    margin-right: 1.5rem !important;
}

.me-5 {
    margin-right: 3rem !important;
}

.me-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.ms-2 {
    margin-left: 0.5rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.ms-4 {
    margin-left: 1.5rem !important;
}

.ms-5 {
    margin-left: 3rem !important;
}

.ms-auto {
    margin-left: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pe-0 {
    padding-right: 0 !important;
}

.pe-1 {
    padding-right: 0.25rem !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}

.pe-3 {
    padding-right: 1rem !important;
}

.pe-4 {
    padding-right: 1.5rem !important;
}

.pe-5 {
    padding-right: 3rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.ps-0 {
    padding-left: 0 !important;
}

.ps-1 {
    padding-left: 0.25rem !important;
}

.ps-2 {
    padding-left: 0.5rem !important;
}

.ps-3 {
    padding-left: 1rem !important;
}

.ps-4 {
    padding-left: 1.5rem !important;
}

.ps-5 {
    padding-left: 3rem !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex !important;
}

.d-none {
    display: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

// Form Fields Start
.form_data {
    .avatar-upload {
        margin-inline: auto;
    }

    input:not(input[type="checkbox"], input[type="radio"]),
    textarea,
    select,
    .css-13cymwt-control {
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #e4e7ea;
        color: #565656;
        outline: none;
        border-radius: 20px;
        padding: 10px;
        font-weight: 500;
    }

    input[type='radio'] {
        accent-color: #000000;
    }

    label {
        font-size: 14px;
        font-weight: 300;
        color: #565656;
        margin-left: 10px;
    }

    span {
        max-width: 100%;
        font-size: 12px;
        font-weight: 300;
        color: #cbcbcb;
        margin-left: 10px;
    }

    textarea {
        resize: none;
    }

    .form_child {
        margin-bottom: 20px;
    }

    .check_parent {
        display: flex;
        align-items: center;
        margin-left: 10px;

        label {
            font-size: 14px;
            font-weight: 400;
            margin-left: 5px;
            color: #041731;
            cursor: pointer;
        }

        input[type="checkbox"] {
            width: 15px;
            height: 15px;
        }
    }

    .radio_parent {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            font-size: 14px;
            font-weight: 400;
            margin-left: 5px;
            color: #041731;
            cursor: pointer;
        }

        .radio_child {
            display: flex;

            div:first-child {
                margin-right: 30px;
            }
        }

    }

    .middle_data {
        position: relative;

        &::before {
            content: '';
            width: 2px;
            height: 100%;
            background-color: #e7e7e7;
            position: absolute;
            top: 0;
            left: -16px;
        }

        &::after {
            content: '';
            width: 2px;
            height: 100%;
            background-color: #e7e7e7;
            position: absolute;
            top: 0;
            right: -16px;
        }
    }
}

// Form Fields End

// Pannel  Heading Start
.form_heading {
    border-color: #2c56f9;
    background-color: #22252b;
    border-radius: 34px 34px 0 0;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    padding: 20px 25px;
}

// Pannel  Heading End
.btn_save {
    // width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    background-color: #3ADB4A;
    border: 1px solid #3ADB4A;
    padding: 6px 12px;
    border-radius: 7px;
    vertical-align: middle;
    cursor: pointer;
    transition: .3s;

    &:hover {
        opacity: .8;
    }
}

.btn_back {
    // width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #4c5667;
    background-color: #e4e7ea;
    border: 1px solid #e4e7ea;
    padding: 6px 12px;
    border-radius: 7px;
    vertical-align: middle;
    cursor: pointer;
    transition: .3s;

    &:hover {
        opacity: .8;
    }
}

.light .ant-modal-body {
    background: #ffffff;
}

.paypalLabel{
color: black;
font-size: 14px;
}
.custom-hosted-field {
    border: 1px solid #76767633;
    font-size: 16px;
    height: 45px;
    margin: 10px 0px;
    border-radius: 10px;
    width: 100%;
    padding-left: 15px;
    box-shadow: 0px 0px 5px #80808013;
}
.myUpload{
    border: 1px solid #8080803b;
    border-radius: 20px;
    padding: 10px 30px;
    text-align: center;
}